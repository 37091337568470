import {
  alias as segmentAlias,
  user as segmentUser,
} from 'features/Analytics/analytics';
import { getUser } from 'helpers/auth';
import logger from 'helpers/logger';

/**
 * This function returns a XState action that, when executed,
 * calls segment.alias on the user.
 */
const alias = () => ({
  exec: () => {
    const user = getUser();

    const uid = user?.uid;
    const anonymousId = segmentUser()?.anonymousId();

    /**
      No matter the user consent, we disable fake CAPI for alias because:
      - it does not support alias type
      - it does not provide a UI way to filter them out without noise in `Event Delivery` tab of Segment
    **/
    if (uid && anonymousId) {
      segmentAlias(uid, anonymousId, {
        integrations: {
          'Facebook Conversions API': false,
          'Facebook Conversions API - Marketing Website': false,
          'Facebook Pixel Server Side': false,
        },
      });

      logger.info('ℹ️ SEGMENT ALIAS');
    }
  },
  type: 'alias',
});

export default alias;
