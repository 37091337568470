import { identify as segmentIdentify } from 'features/Analytics/analytics';
import { getUser } from 'helpers/auth';
import logger from 'helpers/logger';

/**
 * This function takes user traits as input, and returns a XState action that, when executed,
 * calls segment.identify on the user with these traits.
 * If the user is not logged in, it will make an anonymous identify call
 * (Segment will automatically tie an Anonymous ID if a User ID is not provided,
 *  see https://segment.com/docs/connections/spec/identify/#anonymous-id).
 */
const identify = (
  traits: Record<string, string | number | boolean | null | undefined>,
) => ({
  exec: () => {
    const user = getUser();

    /**
      No matter the user consent, we disable fake CAPI for identify because:
      - it does not support identify type
      - it does not provide a UI way to filter them out without noise in `Event Delivery` tab of Segment
    **/
    segmentIdentify(user?.uid, traits, {
      integrations: {
        'Facebook Conversions API': false,
        'Facebook Conversions API - Marketing Website': false,
        'Facebook Pixel Server Side': false,
      },
    });

    logger.info('ℹ️ SEGMENT IDENTIFY: ', traits);
  },
  type: 'identify',
});

export default identify;
